import { colord } from 'colord';

import type Components from '../types/components.type';
import colors from './colors';

const components: Components = {
    accordion: {
        default: {
            buttonBackground: colors.secondary.hex,
            hoverColor: colors.secondary.tints[0].hex,
            headingColor: colors.text.hex,
            iconBackground: colors.primary.hex,
            iconColor: colors.primaryContrast.hex,
            open: {
                background: colors.primaryContrast.hex,
                color: colors.text.hex,
                iconBackground: colors.primary.hex,
                iconColor: colors.primaryContrast.hex
            }
        },
        tertiary: {
            buttonBackground: colors.secondary.hex,
            hoverColor: colors.secondary.tints[0].hex,
            headingColor: colors.text.hex,
            iconBackground: colors.tertiary.hex,
            iconColor: colors.primaryContrast.hex,
            open: {
                background: colors.tertiary.hex,
                color: colors.primaryContrast.hex,
                iconBackground: colors.primaryContrast.hex,
                iconColor: colors.tertiary.hex
            }
        },
        white: {
            buttonBackground: colors.secondary.hex,
            hoverColor: colors.secondary.tints[0].hex,
            headingColor: colors.text.hex,
            iconBackground: colors.primaryContrast.hex,
            iconColor: colors.text.hex,
            open: {
                background: colors.secondary.tints[0].hex,
                color: colors.text.hex,
                iconBackground: colors.primaryContrast.hex,
                iconColor: colors.text.hex
            }
        },
        petrol: {
            buttonBackground: colors.secondary.hex,
            hoverColor: colors.secondary.tints[0].hex,
            headingColor: colors.text.hex,
            iconBackground: colors.accents[10].hex,
            iconColor: colors.primaryContrast.hex,
            open: {
                background: colors.secondary.tints[0].hex,
                color: colors.text.hex,
                iconBackground: colors.primaryContrast.hex,
                iconColor: colors.text.hex
            }
        },
        petrolButton: {
            buttonBackground: colors.accents[10].hex,
            hoverColor: colors.accents[10].hex,
            headingColor: colors.secondary.hex,
            iconBackground: colors.secondary.hex,
            iconColor: colors.accents[10].hex,
            open: {
                background: colors.accents[10].hex,
                color: colors.secondary.hex,
                iconBackground: colors.primaryContrast.hex,
                iconColor: colors.text.hex
            }
        }
    },
    cards: {
        campaign: {
            background: 'rgb(255, 255, 255)'
        }
    },
    sectionNavigation: {
        background: colors.tertiary.hex,
        link: {
            color: colors.primaryContrast.hex
        },
        disabledItem: {
            color: `#B3B3B3`
        }
    },
    supportBlock: {
        contactInfo: {
            background: `${colors.secondary.hex} 0%, ${colors.secondary.hex} 36%, ${colors.secondary.tints[0].hex} 100%`
        },
        courseInformation: {
            background: 'linear-gradient(1deg, #D83D0E 0%,  #C5370D  25%, #AA0006 100%)'
        }
    },
    tabNavigation: {
        tabs: {
            background: colors.secondary.hex,
            boxShadow: `0 0.875rem 1.5rem 0 rgba(${colors.accents[0]}, 0.15)`
        }
    },
    breadcrumbs: {
        background: '#f2f2f2'
    },
    documentShowcase: {
        background:
            'linear-gradient(139.13deg, white 0%, #f9fafa 35.26%, #eef2f2 64.74%, #e8eded 100%)'
    },
    social: {
        background:
            'linear-gradient(139.13deg, white 0%, #f9fafa 35.26%, #eef2f2 64.74%, #e8eded 100%)'
    },
    percentageBar: {
        backgroundContrast:
            'linear-gradient(155.23deg, #FFFFFF 0%, #F9FAFA 35.26%, #EEF2F2 64.74%, #E8EDED 100%)',
        backgroundPrimary: colors.tertiary.hex
    },
    loader: {
        colors: {
            primary: '#CE2036',
            secondary: '#8A295D'
        }
    },
    externalLink: {
        background:
            'linear-gradient(139.13deg, white 0%, #F9FAFA 35.26%, #EEF2F2 64.74%, #E8EDED 100%)',
        colors: {
            contrast: '#f8f9f9'
        }
    },
    events: {
        default: {
            text: colord(colors.secondary.tints[0].hex).darken(0.2).toHex(),
            background: colors.secondary.tints[0].hex
        },
        eventUndergraduateOpenDay: {
            text: colord(colors.accents[11].hex).darken(0.2).toHex(),
            background: colors.accents[11].hex
        },
        eventPostgraduateOpenDay: {
            text: colord(colors.accents[12].hex).darken(0.2).toHex(),
            background: colors.accents[12].hex
        },
        eventCadetshipsOpenDay: {
            text: colord(colors.accents[13].hex).darken(0.2).toHex(),
            background: colors.accents[13].hex
        },
        eventApprenticeshipsOpenDay: {
            text: colors.accents[19].hex,
            background: colors.accents[15].hex
        },
        eventApplicantTasterDays: {
            text: colord(colors.accents[27].hex).darken(0.2).toHex(),
            background: colors.accents[27].hex
        }
    },
    siteAlert: {
        critical: {
            backgroundColour: colors.primary.hex,
            textColour: colors.primaryContrast.hex,
            linkColour: colors.primary.hex
        },
        'non-urgent': {
            backgroundColour: colors.accents[7].hex,
            textColour: colors.primaryContrast.hex,
            linkColour: colors.accents[7].hex
        },
        urgent: {
            backgroundColour: colors.accents[3].hex,
            textColour: colors.tertiary.hex,
            linkColour: colors.tertiary.hex
        },
        serious: {
            backgroundColour: colors.secondaryContrast.hex,
            textColour: colors.primaryContrast.hex,
            linkColour: colors.secondaryContrast.hex
        },
        statement: {
            backgroundColour: colors.accents[2].hex,
            textColour: colors.primaryContrast.hex,
            linkColour: colors.accents[2].hex
        }
    },
    keyStatsCourse: {
        undergraduate: {
            colours: [colors.accents[1].hex, colors.accents[6].hex, colors.accents[4].hex]
        },
        foundation: {
            colours: [colors.accents[4].hex, colors.accents[8].hex, colors.accents[3].hex]
        }
    }
};

export default components;
