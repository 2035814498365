import type Elements from '../types/elements.type';

import colors from './colors';
import gradients from './gradients';
import commonStyles from './commonStyles';
import { colord } from 'colord';

const elements: Elements = {
    input: {
        border: '#575757'
    },
    button: {
        default: {
            color: colors.interactiveContrast.hex,
            background: colors.interactive.hex,
            border: 'none',
            hover: {
                background: colord(colors.interactive.hex).darken(0.1).toHex()
            }
        },
        neutral: {
            color: colors.text.hex,
            background: colors.secondary.tints[1].hex,
            border: 'none',
            hover: {
                background: colors.secondary.tints[2].hex
            }
        },
        positive: {
            color: colors.text.hex,
            background: '#6cd45f',
            border: 'none',
            hover: {
                background: colord('#6cd45f').lighten(0.1).toHex()
            }
        },
        negative: {
            color: colors.primaryContrast.hex,
            background: colors.primary.hex,
            border: 'none',
            hover: {
                background: colord(colors.primary.hex).darken(0.1).toHex()
            }
        },
        outline: {
            color: colors.interactive.hex,
            background: 'transparent',
            border: `${commonStyles.brandPixel} solid ${colors.interactive.hex}`,
            hover: {
                color: colors.secondary.hex,
                background: colors.interactive.hex
            }
        },
        primary: {
            color: colors.primary.hex,
            background: colors.secondary.hex,
            border: 'none',
            hover: {
                background: colord(colors.secondary.hex).darken(0.1).toHex()
            }
        },
        secondary: {
            color: colors.tertiary.hex,
            background: colors.secondary.hex,
            border: 'none',
            hover: {
                background: colord(colors.secondary.hex).darken(0.1).toHex()
            }
        },
        secondaryOutline: {
            color: colors.secondary.hex,
            background: `transparent`,
            border: `${commonStyles.brandPixel} solid ${colors.secondary.hex}`,
            hover: {
                color: colors.secondaryContrast.hex,
                background: colors.secondary.hex
            }
        },
        tertiary: {
            color: colors.tertiaryContrast.hex,
            background: colors.tertiary.hex,
            border: 'none',
            hover: {
                background: colord(colors.tertiary.hex).darken(0.1).toHex()
            }
        },
        tertiaryTextWhiteBg: {
            color: colors.tertiary.hex,
            background: colors.primaryContrast.hex,
            border: 'none',
            hover: {
                background: colord(colors.primaryContrast.hex).darken(0.1).toHex()
            }
        },
        tertiaryOutline: {
            color: colors.tertiary.hex,
            border: `${commonStyles.brandPixel} solid ${colors.tertiary.hex}`,
            background: `transparent`,
            hover: {
                color: colors.tertiaryContrast.hex,
                background: colors.tertiary.hex
            }
        },
        accentOutline: {
            color: colors.accents[3].hex,
            border: `${commonStyles.brandPixel} solid ${colors.accents[3].hex}`,
            background: `transparent`,
            hover: {
                color: colors.accents[3].hex,
                background: colors.accents[3].hex
            }
        },
        petrolBlue: {
            color: colors.accents[10].hex,
            border: `${commonStyles.brandPixel} solid ${colors.accents[10].hex}`,
            background: colors.primaryContrast.hex,
            hover: {
                color: colors.primaryContrast.hex,
                background: colors.accents[10].hex
            }
        },
        tertiaryAccentSeven: {
            color: colors.accents[7].hex,
            border: `${commonStyles.brandPixel} solid ${colors.tertiary.hex}`,
            background: colors.tertiary.hex,
            hover: {
                color: colors.tertiary.hex,
                background: colors.accents[7].hex
            }
        },
        tertiaryAccentEight: {
            color: colors.accents[8].hex,
            border: `${commonStyles.brandPixel} solid ${colors.tertiary.hex}`,
            background: colors.tertiary.hex,
            hover: {
                color: colors.tertiary.hex,
                background: colors.accents[8].hex
            }
        },
        accentFourTertiary: {
            color: colors.tertiary.hex,
            border: `${commonStyles.brandPixel} solid ${colors.accents[4].hex}`,
            background: colors.accents[4].hex,
            hover: {
                color: colors.tertiary.hex,
                background: colors.accents[4].hex
            }
        },
        accentSixTertiary: {
            color: colors.tertiary.hex,
            border: `${commonStyles.brandPixel} solid ${colors.accents[6].hex}`,
            background: colors.accents[6].hex,
            hover: {
                color: colors.tertiary.hex,
                background: colors.accents[6].hex
            }
        },
        accentTwentyFive: {
            color: colors.primaryContrast.hex,
            border: 'none',
            background: colors.accents[25].hex
        },
        campaignPrimary: {
            color: colors.interactiveContrast.hex,
            background: colors.campaigns.clearing.primary.hex,
            border: 'none',
            hover: {
                background: colord(colors.campaigns.clearing.primary.hex).darken(0.1).toHex()
            }
        },
        campaignPrimaryContrast: {
            color: colors.primary.hex,
            background: colors.campaigns.clearing.primaryContrast.hex,
            border: 'none',
            hover: {
                background: colord(colors.campaigns.clearing.primaryContrast.hex)
                    .darken(0.1)
                    .toHex()
            }
        },
        campaignSecondary: {
            color: colors.interactiveContrast.hex,
            background: colors.campaigns.clearing.secondary.hex,
            border: 'none',
            hover: {
                background: colord(colors.campaigns.clearing.secondary.hex).darken(0.1).toHex()
            }
        },
        campaignAccentFive: {
            color: colors.accents[5].hex,
            background: colors.primaryContrast.hex,
            border: 'none',
            hover: {
                background: colord(colors.campaigns.clearing.secondary.hex).darken(0.1).toHex()
            }
        },
        campaignAccentSeven: {
            color: colors.accents[7].hex,
            border: 'none',
            background: colors.primaryContrast.hex
        },
        campaignAccentNine: {
            color: colors.accents[9].hex,
            border: 'none',
            background: colors.primaryContrast.hex
        },
        campaignAccentTen: {
            color: colors.accents[10].hex,
            border: 'none',
            background: colors.primaryContrast.hex
        }
    },
    buttonIcon: {
        primary: {
            border: `${commonStyles.brandPixel} solid ${colors.primary.hex}`,
            background: colors.primaryContrast.hex,
            color: colors.primary.hex,
            hover: {
                color: colors.primaryContrast.hex,
                background: colors.primary.hex
            }
        },
        primaryContrast: {
            color: colors.tertiary.hex,
            border: `${commonStyles.brandPixel} solid ${colors.primaryContrast.hex}`,
            background: colors.primaryContrast.hex,
            hover: {
                color: colors.primaryContrast.hex,
                background: colors.tertiary.hex
            }
        },
        tertiarySolid: {
            color: colors.tertiaryContrast.hex,
            background: colors.tertiary.hex
        },
        secondaryTintOne: {
            color: colors.tertiary.hex,
            border: `${commonStyles.brandPixel} solid ${colors.secondary.tints[1].hex}`,
            background: colors.secondary.tints[1].hex,
            hover: {
                color: colors.primaryContrast.hex,
                background: colors.tertiary.hex
            }
        },
        accentFive: {
            color: colors.accents[5].hex,
            border: `${commonStyles.brandPixel} solid ${colors.accents[5].hex}`,
            background: colors.primaryContrast.hex,
            hover: {
                color: colors.primaryContrast.hex,
                background: colors.accents[5].hex
            }
        },
        accentFiveSolid: {
            color: colors.primaryContrast.hex,
            border: `${commonStyles.brandPixel} solid ${colors.accents[5].hex}`,
            background: colors.accents[5].hex,
            hover: {
                color: colors.primaryContrast.hex,
                background: colors.accents[5].hex
            }
        },
        accentSix: {
            color: colors.accents[6].hex,
            border: `${commonStyles.brandPixel} solid ${colors.accents[6].hex}`,
            background: 'none',
            hover: {
                color: colors.primaryContrast.hex,
                background: colors.accents[6].hex
            }
        },
        accentSeven: {
            color: colors.accents[7].hex,
            border: `${commonStyles.brandPixel} solid ${colors.accents[7].hex}`,
            background: 'none',
            hover: {
                color: colors.primaryContrast.hex,
                background: colors.accents[7].hex
            }
        },
        accentNineSolid: {
            color: colors.primaryContrast.hex,
            border: `${commonStyles.brandPixel} solid ${colors.accents[9].hex}`,
            background: colors.accents[9].hex,
            hover: {
                color: colors.primaryContrast.hex,
                background: colors.accents[9].hex
            }
        },
        accentTenSolid: {
            color: colors.primaryContrast.hex,
            border: `${commonStyles.brandPixel} solid ${colors.accents[10].hex}`,
            background: colors.accents[10].hex,
            hover: {
                color: colors.primaryContrast.hex,
                background: colors.accents[10].hex
            }
        },
        accentThirteenSolid: {
            color: colors.secondaryContrast.hex,
            border: `${commonStyles.brandPixel} solid ${colors.accents[13].hex}`,
            background: colors.accents[13].hex,
            hover: {
                color: colors.primaryContrast.hex,
                border: `${commonStyles.brandPixel} solid ${colors.primaryContrast.hex}`,
                background: colors.secondary.tints[8].hex
            }
        },
        accentFifteenSolid: {
            color: colors.secondaryContrast.hex,
            border: `${commonStyles.brandPixel} solid ${colors.accents[15].hex}`,
            background: colors.accents[15].hex,
            hover: {
                color: colors.primaryContrast.hex,
                border: `${commonStyles.brandPixel} solid ${colors.primaryContrast.hex}`,
                background: colors.secondary.tints[8].hex
            }
        },
        accentTwentySevenSolid: {
            color: colors.secondaryContrast.hex,
            border: `${commonStyles.brandPixel} solid ${colors.accents[27].hex}`,
            background: colors.accents[27].hex,
            hover: {
                color: colors.primaryContrast.hex,
                border: `${commonStyles.brandPixel} solid ${colors.primaryContrast.hex}`,
                background: colors.secondary.tints[8].hex
            }
        }
    },
    buttonGradient: {
        default: {
            in: gradients.secondary,
            out: gradients.secondaryOpposite
        },
        accentFour: {
            in: gradients.accent.four,
            out: gradients.accent.fourOpposite
        },
        accentTwo: {
            in: gradients.accent.two,
            out: gradients.accent.twoOpposite
        },
        accentThree: {
            in: gradients.accent.three,
            out: gradients.accent.threeOpposite
        }
    },
    link: {
        default: {
            color: colors.interactive.hex,
            icon: { color: colors.secondary.hex }
        },
        text: {
            color: colors.text.hex,
            icon: { color: colors.secondary.hex }
        },
        secondary: {
            color: colors.secondary.hex,
            icon: { color: colors.tertiary.hex }
        },
        secondaryPrimary: {
            color: colors.secondary.hex,
            icon: { color: colors.primary.hex }
        },
        secondaryFive: {
            color: colors.secondary.hex,
            icon: { color: colors.accents[5].hex }
        },
        // Numbers refer to accents and match up with their index in the colors file
        one: {
            color: colors.accents[1].hex,
            icon: { color: colors.secondary.hex }
        },
        three: {
            color: colors.accents[3].hex,
            icon: { color: colors.secondary.hex }
        },
        threeDark: {
            color: colors.accents[3].hex,
            icon: { color: colors.tertiary.hex }
        },
        four: {
            color: colors.accents[4].hex,
            icon: { color: colors.secondary.hex }
        },
        fourDark: {
            color: colors.accents[4].hex,
            icon: { color: colors.tertiary.hex }
        },
        five: {
            color: colors.accents[5].hex,
            icon: { color: colors.primaryContrast.hex }
        },
        six: {
            color: colors.accents[6].hex,
            icon: { color: colors.secondary.hex }
        },
        sixDark: {
            color: colors.accents[6].hex,
            icon: { color: colors.tertiary.hex }
        },
        seven: {
            color: colors.accents[7].hex,
            icon: { color: colors.secondary.hex }
        },
        eight: {
            color: colors.accents[8].hex,
            icon: { color: colors.secondary.hex }
        },
        nine: {
            color: colors.accents[9].hex,
            icon: { color: colors.secondary.hex }
        },
        ten: {
            color: colors.accents[10].hex,
            icon: { color: colors.secondary.hex }
        }
    },
    blockGradient: {
        one: `linear-gradient(224.72deg, #CE2036 0%, #8A295D 100%)`,
        two: `linear-gradient(225deg, #F58F1E 0%, #F4831F 12.61%, #F26820 23.82%, #F04722 38.74%, #EE2C23 62.43%, #E30613 100%);`,
        three: `linear-gradient(45deg, #9F519A 0%, #97589F 33.73%, #8269AD 56.15%, #677FBF 70.63%, #4B95D1 80.52%, #36A6DF 89.19%, #2EADE4 100%);`,
        four: ` linear-gradient(225deg, #2BAC70 0%, #2E2B86 100%);`
    }
};

export default elements;
